<template>
  <PageLoader />

  <router-view />

  <Footer />
</template>

<script>
import NavBar from './components/Navbar.vue';
import Footer from './components/Footer.vue'
import PageLoader from './components/PageLoader.vue'


export default {
  components: {
    NavBar,
    Footer,
    PageLoader
  }
}

</script>
<style>
nav a.router-link-exact-active {
  color: #159593;
}

body {
  overflow-x: hidden;
}
</style>
