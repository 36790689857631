<template>
  <header class="pl-8 py-2 fixed z-10 w-full " :class="navbarBackgroundClass">
    <nav class="grid grid-cols-3 max-md:grid-cols-2 items-center  max-container">
      <router-link to="/">
        <img src="../assets/img/LogoSozo.svg" class="w-[250px]" alt="Logo" />
      </router-link>

      <!-- Mobile Menu Button (Right Side) -->
      <button @click="toggleMobileMenu"
        class="md:hidden text-white text-[25px] font-['Trade Gothic Next LT Pro'] w-[20%] font-bold ml-auto mr-4">
        ☰
      </button>
      <!-- Desktop Menu (Center) -->
      <div class="flex col-span-1 items-center justify-center gap-32 max-md:hidden ">
        <router-link :to="'/'"
          class="text-white text-[14px] font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">Home</router-link>
        <router-link :to="'/about'"
          class="text-white text-[14px] font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">About</router-link>
        <router-link :to="'/services'"
          class="text-white text-[14px] font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">Services</router-link>
        <a href="#contact"
          class="text-white text-[14px] cursor-pointer font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">Contact</a>
      </div>
      <!-- Mobile Menu (Center) -->
      <ul v-if="showMobileMenu" :class="[navbarBackgroundClass, 'menu-center']"
        class="md:hidden flex flex-col gap-8 justify-center items-center h-[100vh]">
        <router-link to="/"
          class="text-white text-[26px] font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">Home</router-link>
        <router-link to="/about"
          class="text-white text-[26px] font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">About</router-link>
        <router-link to="/services"
          class="text-white text-[26px] font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">Services</router-link>
        <a href="#contact"
          class="text-white text-[26px] cursor-pointer font-['Trade Gothic Next LT Pro'] font-semibold hover:text-[#159593]">Contact</a>
        <!-- <div class="flex items-center gap-4">
          <a href="#" class="font-['Trade Gothic Next LT Pro'] font-regular text-white text-[12px]">AL</a>
          <span class="text-green-400">|</span>
          <a href="#" class="font-['Trade Gothic Next LT Pro'] font-regular text-white text-[12px]">EN</a>
        </div> -->
      </ul>
    </nav>
  </header>
</template>



<script>
export default {
  data() {
    return {
      isNavbarOpaque: false,
      showMobileMenu: false,
    };
  },
  computed: {
    navbarBackgroundClass() {
      return this.isNavbarOpaque ? 'bg-blurred-color' : 'bg-transparent';
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {

      const scrollPosition = window.scrollY;

      if (scrollPosition >= 300) {
        this.isNavbarOpaque = true;
      } else if (scrollPosition < 1) {
        this.isNavbarOpaque = false;
        if (this.showMobileMenu)
          this.showMobileMenu = !this.showMobileMenu
      }
      else if (this.isNavbarOpaque == true) {
        return
      }


    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      this.isNavbarOpaque = true;
    },
  },
};
</script>

<style scoped>
.bg-transparent {
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0);
}

.bg-blurred-color {
  background: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(5px);
}

.menu-center {
  width: 100%;
  position: absolute;
  top: 100%;

  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Adjust this if you want the items centered horizontally */
  padding-left: 50px;
  padding-bottom: 70%;
  /* Adjust the left padding as needed */
  transition: all 2s;
}

.menu-center a {
  /* Add this rule to remove the black background on links */
  background-color: transparent;
}

</style>
