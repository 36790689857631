<template>
  <div class="background">
    <div class="bg-[#000000da]">
      <div class="h-[90vh] grid grid-cols-2 max-md:grid-cols-1" id="contact">
        <div class="w-[100%] flex justify-center items-center">
          <div class="text-[#87A8A8] text-center">
            <p class="text-[60px] max-sm:text-[32px]">
              Have a project? <br />
              Let's Work Together
            </p>
          </div>
        </div>
        <div
          class="w-[100%] flex items-center justify-center max-md:justify-center"
        >
          <div
            class="w-[70%] max-sm:w-[90%] max-md:h-full h-[80vh] contact-back rounded-[69px] grid grid-cols-1"
          >
            <div class="flex justify-center items-end h-16">
              <p class="font-['Raleway'] text-[#87A8A8] font-bold text-[30px]">
                Contact Us
              </p>
            </div>
            <div class="grid gap-0 grid-cols-1">
              <p
                class="font-['Microsoft JhengHei'] text-[#87A8A8] ml-7 text-[20px] max-sm:text-[20px]"
              >
                I'm interested in..
              </p>
              <div class="grid mt-0 grid-cols-3 justify-items-center">
                <!-- UI/UX -->
                <input
                  type="radio"
                  name="interest"
                  value="UI/UX"
                  id="option1"
                  class="input-radio"
                  v-model="selectedOption"
                  @change="markAsTouched('interest')"
                />
                <label
                  for="option1"
                  class="label-radio rounded-[5px] text-[27px] pt-1 text-center"
                  :class="{ selected: selectedOption === 'UI/UX' }"
                  >UI/UX</label
                >

                <!-- Design -->
                <input
                  type="radio"
                  name="interest"
                  value="Design"
                  id="option2"
                  class="input-radio"
                  v-model="selectedOption"
                  @change="markAsTouched('interest')"
                />
                <label
                  for="option2"
                  class="label-radio rounded-[5px] text-[27px] pt-1 text-center"
                  :class="{ selected: selectedOption === 'Design' }"
                  >Design</label
                >

                <!-- Marketing -->
                <input
                  type="radio"
                  name="interest"
                  value="Marketing"
                  id="option3"
                  class="input-radio"
                  v-model="selectedOption"
                  @change="markAsTouched('interest')"
                />
                <label
                  for="option3"
                  class="label-radio rounded-[5px] text-[27px] pt-1 text-center"
                  :class="{ selected: selectedOption === 'Marketing' }"
                  >Marketing</label
                >

                <!-- Software -->
                <input
                  type="radio"
                  name="interest"
                  value="Software"
                  id="option4"
                  class="input-radio"
                  v-model="selectedOption"
                  @change="markAsTouched('interest')"
                />
                <label
                  for="option4"
                  class="label-radio rounded-[5px] text-[27px] pt-1 text-center"
                  :class="{ selected: selectedOption === 'Software' }"
                  >Software</label
                >

                <!-- Web -->
                <input
                  type="radio"
                  name="interest"
                  value="Web"
                  id="option5"
                  class="input-radio"
                  v-model="selectedOption"
                  @change="markAsTouched('interest')"
                />
                <label
                  for="option5"
                  class="label-radio rounded-[5px] text-[27px] pt-1 text-center"
                  :class="{ selected: selectedOption === 'Web' }"
                  >Web</label
                >

                <!-- Other -->
                <input
                  type="radio"
                  name="interest"
                  value="Other"
                  id="option6"
                  class="input-radio"
                  v-model="selectedOption"
                  @change="markAsTouched('interest')"
                />
                <label
                  for="option6"
                  class="label-radio rounded-[5px] text-[27px] pt-1 text-center"
                  :class="{ selected: selectedOption === 'Other' }"
                  >Other..</label
                >
              </div>
              <!-- Add a span for radio button error message -->
            </div>

            <div class="grid grid-cols-1 justify-items-center mt-9">
              <div class="w-[90%] mb-3">
                <span
                  v-if="touched.interest && !selectedOption"
                  class="text-red-500 text-[12px]"
                  >Please select an interest</span
                >
                <input
                  type="text"
                  placeholder="Your name"
                  class="w-full border-b-2 text-[18px] max-sm:text-[16px] bg-[#fff0] caret-[#87a8a8] text-[#87a8a8] border-[#159593] focus:outline-none"
                  v-model="name"
                  required
                  @input="markAsTouched('name')"
                />
                <!-- Add a span for error message -->
                <span
                  v-if="touched.name && !name"
                  class="text-red-500 text-[12px]"
                  >Name is required</span
                >
              </div>
              <div class="w-[90%] mb-3">
                <input
                  type="email"
                  placeholder="Your email"
                  class="w-full text-[18px] border-b-2 max-sm:text-[16px] bg-[#fff0] caret-[#87a8a8] text-[#87a8a8] border-[#159593] focus:outline-none"
                  v-model="email"
                  required
                  @input="markAsTouched('email')"
                />
                <!-- Add a span for error message -->
                <span
                  v-if="touched.email && !email"
                  class="text-red-500 text-[12px]"
                  >Email is required</span
                >
                <span
                  v-else-if="touched.email && !isValidEmail(email)"
                  class="text-red-500"
                  >Invalid email format</span
                >
              </div>
              <div class="w-[90%] mb-">
                <textarea
                  placeholder="Tell us about your project"
                  class="w-full text-[18px] border-b-2 max-sm:text-[16px] bg-[#fff0] caret-[#87a8a8] text-[#87a8a8] border-[#159593] focus:outline-none"
                  required
                  rows="2"
                  style="resize: none"
                  v-model="project"
                  @input="markAsTouched('project')"
                ></textarea>
                <!-- Add a span for error message -->
                <span
                  v-if="touched.project && !project"
                  class="text-red-500 text-[12px]"
                  >Project description is required</span
                >
              </div>
              <!-- ... existing template code ... -->

              <!-- !this font needs fixing causing long load times --> 
              <button
                type="button"
                @click="submitForm"
                class="w-[240px] h-[62px] max-sm:w-[180px] max-sm:h-[42px] max-sm:text-[18px] rounded-[30px] font-['Bahnschrift'] text-[20px] max-md:mt-[25px] mt-[25px] mb-[35px] cursor-pointer text-white bg-[#159593]"
              >
                Send Request
              </button>
            </div>
          </div>
        </div>
        <!-- Modal using Teleport -->
        <Teleport to="body">
          <div
            v-if="isModalOpen"
            class="modal-overlay modal open"
            @click="closeModal"
          >
            <div class="modal" @click.stop>
              <!-- Modal content here -->
              <div class="modal-content">
                <button @click="closeModal" class="close-button">x</button>

                <p v-html="modalContent"></p>
              </div>
            </div>
          </div>
        </Teleport>
      </div>
      <div class="h-[9vh] max-sm:h-[25vh] grid grid-cols-3 max-sm:grid-cols-1">
        <div class="flex justify-center items-center">
          <div
            class="flex-1 flex justify-center items-center gap-20 max-sm:gap-10"
          >
            <router-link
              :to="'/'"
              class="text-white text-[12px] font-['Montserrat'] font-semibold hover:text-[#159593]"
              >Home</router-link
            >
            <router-link
              :to="'/about'"
              class="text-white text-[12px] font-['Montserrat'] font-semibold hover:text-[#159593]"
              >About</router-link
            >
            <router-link
              :to="'/services'"
              class="text-white text-[12px] font-['Montserrat'] font-semibold hover:text-[#159593]"
              >Services</router-link
            >
            <a
              href="#contact"
              class="text-white text-[12px] cursor-pointer font-['Montserrat'] font-semibold hover:text-[#159593]"
              >Contact</a
            >
          </div>
        </div>
        <div class="flex justify-center max-sm:hidden items-center">
          <router-link to="/">
            <img src="../assets/img/Logo.png" alt="" class="w-[20px]" />
          </router-link>
        </div>
        <div class="grid grid-cols-2 max-sm:grid-cols-1">
          <div class="flex items-center max-sm:justify-center">
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@sozo-management.com"
              class="text-white font-['Montserrat'] text-[12px] font-semibold"
              >info@sozo-management.com</a
            >
          </div>
          <div class="flex items-center gap-5 justify-center">
            <!-- Facebook Icon -->
            <a
              href="https://www.facebook.com/Sozo.Management"
              class="h-4 w-4"
              @mouseover="changeFillColor('facebook')"
              @mouseleave="resetFillColor"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                id="Facebook"
              >
                <path
                  :fill="hoveredIcon === 'facebook' ? '#159593' : '#ffffff'"
                  fill-rule="evenodd"
                  d="M12 5.5H9v-2a1 1 0 0 1 1-1h1V0H9a3 3 0 0 0-3 3v2.5H4V8h2v8h3V8h2l1-2.5z"
                  clip-rule="evenodd"
                  class="color1976d2 svgShape"
                ></path>
              </svg>
            </a>

            <!-- Instagram Icon -->
            <a
              href="https://www.instagram.com/sozo.management/"
              class="text-white h-4 w-4"
              @mouseover="changeFillColor('instagram')"
              @mouseleave="resetFillColor"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2476 2476"
                id="Instagram"
              >
                <path
                  ref="instagramPath"
                  :fill="hoveredIcon === 'instagram' ? '#159593' : '#ffff'"
                  d="M825.4 1238c0-227.9 184.7-412.7 412.6-412.7 227.9 0 412.7 184.8 412.7 412.7 0 227.9-184.8 412.7-412.7 412.7-227.9 0-412.6-184.8-412.6-412.7m-223.1 0c0 351.1 284.6 635.7 635.7 635.7s635.7-284.6 635.7-635.7-284.6-635.7-635.7-635.7S602.3 886.9 602.3 1238m1148-660.9c0 82 66.5 148.6 148.6 148.6 82 0 148.6-66.6 148.6-148.6s-66.5-148.5-148.6-148.5-148.6 66.5-148.6 148.5M737.8 2245.7c-120.7-5.5-186.3-25.6-229.9-42.6-57.8-22.5-99-49.3-142.4-92.6-43.3-43.3-70.2-84.5-92.6-142.3-17-43.6-37.1-109.2-42.6-229.9-6-130.5-7.2-169.7-7.2-500.3s1.3-369.7 7.2-500.3c5.5-120.7 25.7-186.2 42.6-229.9 22.5-57.8 49.3-99 92.6-142.4 43.3-43.3 84.5-70.2 142.4-92.6 43.6-17 109.2-37.1 229.9-42.6 130.5-6 169.7-7.2 500.2-7.2 330.6 0 369.7 1.3 500.3 7.2 120.7 5.5 186.2 25.7 229.9 42.6 57.8 22.4 99 49.3 142.4 92.6 43.3 43.3 70.1 84.6 92.6 142.4 17 43.6 37.1 109.2 42.6 229.9 6 130.6 7.2 169.7 7.2 500.3 0 330.5-1.2 369.7-7.2 500.3-5.5 120.7-25.7 186.3-42.6 229.9-22.5 57.8-49.3 99-92.6 142.3-43.3 43.3-84.6 70.1-142.4 92.6-43.6 17-109.2 37.1-229.9 42.6-130.5 6-169.7 7.2-500.3 7.2-330.5 0-369.7-1.2-500.2-7.2M727.6 7.5c-131.8 6-221.8 26.9-300.5 57.5-81.4 31.6-150.4 74-219.3 142.8C139 276.6 96.6 345.6 65 427.1 34.4 505.8 13.5 595.8 7.5 727.6 1.4 859.6 0 901.8 0 1238s1.4 378.4 7.5 510.4c6 131.8 26.9 221.8 57.5 300.5 31.6 81.4 73.9 150.5 142.8 219.3 68.8 68.8 137.8 111.1 219.3 142.8 78.8 30.6 168.7 51.5 300.5 57.5 132.1 6 174.2 7.5 510.4 7.5 336.3 0 378.4-1.4 510.4-7.5 131.8-6 221.8-26.9 300.5-57.5 81.4-31.7 150.4-74 219.3-142.8 68.8-68.8 111.1-137.9 142.8-219.3 30.6-78.7 51.6-168.7 57.5-300.5 6-132.1 7.4-174.2 7.4-510.4s-1.4-378.4-7.4-510.4c-6-131.8-26.9-221.8-57.5-300.5-31.7-81.4-74-150.4-142.8-219.3C2199.4 139 2130.3 96.6 2049 65c-78.8-30.6-168.8-51.6-300.5-57.5-132-6-174.2-7.5-510.4-7.5-336.3 0-378.4 1.4-510.5 7.5"
                  fill="#ffff"
                  class="color00000 svgShape"
                ></path>
              </svg>
            </a>

            <!-- LinkedIn Icon -->
            <a
              href="https://www.linkedin.com/company/sozo-management/"
              class="text-white h-4 w-4"
              @mouseover="changeFillColor('linkedin')"
              @mouseleave="resetFillColor"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 5 1036 990"
                id="Linkedin"
              >
                <path
                  :fill="hoveredIcon === 'linkedin' ? '#159593' : '#ffffff'"
                  d="M0 120c0-33.334 11.667-60.834 35-82.5C58.333 15.833 88.667 5 126 5c36.667 0 66.333 10.666 89 32 23.333 22 35 50.666 35 86 0 32-11.333 58.666-34 80-23.333 22-54 33-92 33h-1c-36.667 0-66.333-11-89-33S0 153.333 0 120zm13 875V327h222v668H13zm345 0h222V622c0-23.334 2.667-41.334 8-54 9.333-22.667 23.5-41.834 42.5-57.5 19-15.667 42.833-23.5 71.5-23.5 74.667 0 112 50.333 112 151v357h222V612c0-98.667-23.333-173.5-70-224.5S857.667 311 781 311c-86 0-153 37-201 111v2h-1l1-2v-95H358c1.333 21.333 2 87.666 2 199 0 111.333-.667 267.666-2 469z"
                  fill="#ffffff"
                  class="color000000 svgShape"
                ></path>
              </svg>
            </a>

            <!-- Phone Icon -->
            <a
              href="tel:+38344508057"
              class="text-white h-4 w-4"
              @mouseover="changeWhatsappHover"
              @mouseleave="resetWhatsappHover"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 737.509 740.824"
                id="Whatsapp"
              >
                <!-- Add a condition for hover -->
                <path
                  :fill="whatsappHover ? '#159593' : '#ffffff'"
                  fill-rule="evenodd"
                  d="M630.056 107.658C560.727 38.271 468.525 .039 370.294 0 167.891 0 3.16 164.668 3.079 367.072c-.027 64.699 16.883 127.855 49.016 183.523L0 740.824l194.666-51.047c53.634 29.244 114.022 44.656 175.481 44.682h.151c202.382 0 367.128-164.689 367.21-367.094.039-98.088-38.121-190.32-107.452-259.707m-259.758 564.8h-.125c-54.766-.021-108.483-14.729-155.343-42.529l-11.146-6.613-115.516 30.293 30.834-112.592-7.258-11.543c-30.552-48.58-46.689-104.729-46.665-162.379C65.146 198.865 202.065 62 370.419 62c81.521.031 158.154 31.81 215.779 89.482s89.342 134.332 89.311 215.859c-.07 168.242-136.987 305.117-305.211 305.117m167.415-228.514c-9.176-4.591-54.286-26.782-62.697-29.843-8.41-3.061-14.526-4.591-20.644 4.592-6.116 9.182-23.7 29.843-29.054 35.964-5.351 6.122-10.703 6.888-19.879 2.296-9.175-4.591-38.739-14.276-73.786-45.526-27.275-24.32-45.691-54.36-51.043-63.542-5.352-9.183-.569-14.148 4.024-18.72 4.127-4.11 9.175-10.713 13.763-16.07 4.587-5.356 6.116-9.182 9.174-15.303 3.059-6.122 1.53-11.479-.764-16.07-2.294-4.591-20.643-49.739-28.29-68.104-7.447-17.886-15.012-15.466-20.644-15.746-5.346-.266-11.469-.323-17.585-.323-6.117 0-16.057 2.296-24.468 11.478-8.41 9.183-32.112 31.374-32.112 76.521s32.877 88.763 37.465 94.885c4.587 6.122 64.699 98.771 156.741 138.502 21.891 9.45 38.982 15.093 52.307 19.323 21.981 6.979 41.983 5.994 57.793 3.633 17.628-2.633 54.285-22.19 61.932-43.616 7.646-21.426 7.646-39.791 5.352-43.617-2.293-3.826-8.41-6.122-17.585-10.714"
                  clip-rule="evenodd"
                  class="color000000 svgShape"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      facebookIcon: faFacebookF,
      selectedOption: "",
      whatsappHover: false,
      hoveredIcon: "",
      name: "",
      email: "",
      project: "",
      isModalOpen: false,
      modalContent: "",
      touched: {
        name: false,
        email: false,
        project: false,
        interest: false,
      },
    };
  },

  methods: {
    markAsTouched(fieldName) {
      this.touched[fieldName] = true;
    },
    changeFillColor(icon) {
      // Change fill color on hover
      this.hoveredIcon = icon;
    },
    isValidEmail(email) {
      // Simple email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    resetFillColor() {
      // Reset fill color on mouse leave
      this.hoveredIcon = "";
    },
    changeWhatsappHover() {
      this.whatsappHover = true;
    },
    resetWhatsappHover() {
      this.whatsappHover = false;
    },
    async submitForm() {
      const formData = {
        selectedOption: this.selectedOption,
        name: this.name,
        email: this.email,
        project: this.project,
      };
      if (
        !this.name ||
        !this.email ||
        !this.project ||
        !this.isValidEmail(this.email)
      ) {
        // Show error modal or message
        this.markAsTouched("project");
        this.markAsTouched("email");
        this.markAsTouched("name");
        this.markAsTouched("interest");

        return;
      }

      try {
        const response = await fetch(
          "https://sozo.sozo-management.com/api/email/send-email", // Updated backend URL
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          const responseData = await response.json();

          toast.success("Email was sent successfully", {
            timeout: 2000,
          });
          resetForm();
        } else {
          // Handle non-successful responses (e.g., show an error message)
          console.error("Error:", response.statusText);
          toast.error("Could not send the email!", {
            timeout: 2000,
          });
          resetForm();
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    },

    closeModal() {
      this.isModalOpen = false;
      location.reload();
    },
  },
};
</script>

<style scoped>
.contact-back {
  background: rgba(255, 255, 255, 0.08);
  /* Adjust the alpha channel as needed */
  backdrop-filter: blur(30px) brightness(115%) opacity(1);
}

.input-radio {
  display: none;
}

.background {
  background-image: url("../assets/img/33905957.png");
}

.label-radio {
  width: 135px;
  height: 54px;
  flex: justify-center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid black;
  display: inline-block;
  cursor: pointer;
  margin: 5px;
  color: #fff;
}

.label-radio.selected {
  background-color: black;
  color: #87a8a8;
  border: none;
}

.label-radio:hover {
  background-color: black;
  color: #159593;
  border: none;
}

@media screen and (max-width: 767px) {
  .label-radio {
    width: 105px;
    /* Set the width to 100% for mobile devices */
    height: 40px;
    font-size: 19px;
    /* Set the height to your desired value for mobile devices */
  }

  .label-radio.selected {
    background-color: black;
    color: #87a8a8;
    border: none;
  }
}

.modal-overlay {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 500px;
  z-index: 2;
  margin-left: 125px;
  background-color: rgba(0, 0, 0, 0.329);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .modal-overlay {
    position: fixed;
    top: 40%;
    left: 8%;
    width: 500px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.329);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal.open {
  display: flex;
}

.txt1 {
  text-decoration: underline;
}

.modal {
  background-color: rgb(0, 0, 0);
  padding: 20px;
  width: 300px;
  z-index: 3;
  border-radius: 8px;
  color: white;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
