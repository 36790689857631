<template>
  <div class="h-[100vh] w-[100%] flex justify-center items-center" v-if="!isLoaded">
    <div class="flex justify-center items-center flex-wrap">
      <div class="w-full flex justify-center pb-9">
        <img src="../assets/img/LogoS.svg" alt="" class="w-[70px]">
      </div>
      <div class="loader w-full flex justify-center">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
        <div class="dot dot-4"></div>
        <div class="dot dot-5"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: () => {
    return {
      isLoaded: false,
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        this.isLoaded = true;
      }
    }
  }
}
</script>

<style scoped>
.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  -webkit-animation: dot-pulse2 1.5s ease-in-out infinite;
  animation: dot-pulse2 1.5s ease-in-out infinite;
}
.dot-1 {
  background-color: #159593;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.dot-2 {
  background-color: #262362;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.dot-3 {
  background-color: #159593;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.dot-4 {
  background-color: #262362;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
.dot-5 {
  background-color: #159593;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
@keyframes dot-pulse2 {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.5;
  }
}
</style>
