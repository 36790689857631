<template>
  <!-- HERO PAGE HERE -->
  <NavBar />
  <div class="home h-[100vh] grid grid-cols-1 items-center">
    <div class="h-[60vh] flex items-end justify-center">
      <p
        class="text-white font-['Qualy'] font-bold text-[55px] max-sm:text-[27px] text-center"
      >
        YOUR IMAGINATION,<br />
        OUR CREATION
      </p>
    </div>
    <div class="h-[40vh] flex justify-center items-end">
      <img
        src="../assets/img/down-arrow.png"
        class="w-[50px] pb-4 animate-bounce"
        alt=""
      />
    </div>
  </div>
  <div class="grid grid-cols-2 max-md:grid-cols-1">
    <div
      class="w-[100%] bg-[#f1faf6] max-sm:h-[50vh] flex items-center justify-center max-sm:order-last"
    >
      <div
        class="w-[620px] h-[400px] max-sm:w-[350px] max-sm:h-[200px] grid grid-cols-1"
      >
        <div class="flex items-center">
          <div class="w-[37px] max-sm:mt-1 h-[3px] green-background"></div>
          <p
            class="text-[40px] ml-4 max-sm:text-[30px] text-[#828282] font-['Bahnschrift']"
          >
            Web & Software Development
          </p>
        </div>
        <p
          class="font-['Bahnschrift'] text-[20px] pt-5 max-md:text-[17px] max-sm:text-[15px] max-sm:text-center text-[#828282]"
        >
          Elevate your digital presence with our secure and user-friendly web
          and software solutions. Embrace innovation as we propel your business
          forward in the dynamic digital landscape.
        </p>
      </div>
    </div>
    <div class="w-[100%]">
      <img class="w-[100%]" src="../assets/img/WebDev.jpg" />
    </div>
  </div>
  <div class="grid grid-cols-2 max-md:grid-cols-1">
    <div class="w-[100%]">
      <img class="w-[100%]" src="../assets/img/Design-img.png" />
    </div>
    <div
      class="w-[100%] max-sm:h-[50vh] flex items-center green-background justify-center"
    >
      <div
        class="w-[620px] h-[400px] max-sm:w-[350px] max-sm:h-[200px] grid grid-cols-1"
      >
        <div class="flex items-center">
          <div class="w-[37px] h-[3px] max-sm:mt-1 blu-back"></div>
          <p
            class="text-[40px] max-sm:text-center ml-4 max-sm:text-[30px] text-[#ffffff] font-['Bahnschrift']"
          >
            Design
          </p>
        </div>
        <p
          class="font-['Bahnschrift'] max-sm:text-center text-[20px] max-md:text-[17px] max-sm:text-[15px] text-[#ffffff]"
        >
          Revamp your business with our innovative design solutions, spanning
          from brandig to user interfaces and 3D graphic art modeling. <br />
          Our skilled team breathes life into your vision with creative finesse
          and strategic insight.
        </p>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 max-md:grid-cols-1">
    <div
      class="w-[100%] bg-[#f1faf6] max-sm:h-[50vh] flex items-center justify-center max-sm:order-last"
    >
      <div
        class="w-[620px] h-[400px] max-sm:w-[350px] max-sm:h-[200px] grid grid-cols-1"
      >
        <div class="flex items-center">
          <div class="w-[37px] h-[3px] green-background"></div>
          <p
            class="text-[40px] ml-4 max-sm:text-[30px] text-[#828282] font-['Bahnschrift']"
          >
            Online Marketing
          </p>
        </div>
        <p
          class="font-['Bahnschrift'] max-sm:text-center text-[20px] max-md:text-[17px] max-sm:text-[15px] text-[#828282]"
        >
          Elevate your brand online with our tailored marketing solutions,
          covering strategic SEO and engaging social media campaigns.<br />
          Our team ensures maximum visibility and audience interaction, guiding
          your brand to digital success with effective and innovative strategies
        </p>
      </div>
    </div>
    <div class="w-[100%]">
      <img class="w-[100%]" src="../assets/img/Marketing-img.png" />
    </div>
  </div>

  <!-- SERVICES PAGE ENDS HERE -->

  <!-- ABOUT US STARTS HERE -->
  <div class="h-[80vh] max-sm:h-full bg-black flex flex-wrap">
    <div class="grid grid-cols-1 w-[100%]">
      <div class="flex max-sm:justify-left  max-sm:w-[80%] text-center gap-3 h-[40px] mt-[60px] ml-[35px]">
        <div class="w-[37px] mt-6 max-sm:mt-6 h-[3px] green-background max-sm:hidden"></div>
        <p
          class="text-[40px] max-sm:text-[30px] w-[363px] text-white font-['Bahnschrift'] font-regular"
        >
          We Are Sozo
        </p>
      </div>

      <div
        class="grid grid-cols-1 max-sm:grid-cols-1 w-[100%] justify-items-center items-center"
      >
        <p
          class="text-white w-[80%] mt-[80px] text-center font-['Trade Gothic Next LT Pro'] max-md:mt-[60px] font-regular text-[20px] max-sm:text-[20px]"
        >
          SOZO, based in Kosovo, has been a pioneer in the digital landscape for
          the past three years. Specializing in web and software development,
          marketing, and design, we offer a comprehensive approach to our
          clients worldwide.
        </p>
        <!-- <div class="flex w-[80%] gap-5 mt-[60px] justify-center">
          <div class="w-[100%] text-white">
            <p class="text-white text-center animate__bounceIn font-['Acumin Pro'] max-md:text-[27px] text-[37px]">
              100 <span class="green-font">+</span>
            </p>
            <p class="text-[18px] text-center max-md:text-[15px] font-['Acumin Pro']">
              TRUSTED COMPANIES
            </p>
          </div>
          <div class="w-[100%] text-white">
            <p class="font-['Acumin Pro'] text-center animate__bounceIn max-md:text-[27px] text-[37px]">
              3 <span class="green-font">+</span>
            </p>
            <p class="text-[18px] text-center max-md:text-[15px] font-['Acumin Pro']">
              YEARS OF EXPERIENCE
            </p>
          </div>
        </div> -->
      </div>
    </div>

    <div class="flex justify-center w-full mt-[60px] items-center">
      <div class="flex justify-center w-[80%] items-center flex-wrap">
        <p
          class="text-[30px] w-[100%] max-sm:text-[30px] text-center text-white font-['Qualy'] font-light"
        >
          partnered with...
        </p>
        <div class="flex w-[100%] mt-24 items-end bg-black justify-center">
          <div
            class="w-[100%] bg-black max-sm:gap-4 grid grid-cols-4 max-sm:grid-cols-1 items-end justify-items-center"
          >
            <div
              class="flex border-4 border-[#121212] rounded-[20px] bg-[#111113] hover:bg-[#1f1f22] items-center justify-center max-sm:w-[200px] w-[80%] h-[95px]"
            >
              <img
                class="w-[130px] max-sm:w-[90px]"
                src="../assets/img/logowhite.png"
                alt=""
              />
            </div>
            <div
              class="flex border-4 border-[#121212] rounded-[20px] bg-[#111113] hover:bg-[#1f1f22] items-center justify-center w-[200px] h-[95px]"
            >
              <img
                class="w-[110px] max-sm:w-[90px]"
                src="../assets/img/ACCESSwhite.png"
                alt=""
              />
            </div>
            <div
              class="flex border-4 border-[#121212] rounded-[20px] bg-[#111113] hover:bg-[#1f1f22] items-center justify-center w-[200px] h-[95px]"
            >
              <img
                class="w-[160px] max-sm:w-[90px]"
                src="../assets/img/Infinity.png"
                alt=""
              />
            </div>
            <div
              class="flex border-4 border-[#121212] rounded-[20px] bg-[#111113] hover:bg-[#1f1f22] items-center justify-center w-[200px] h-[95px] "
            >
              <img
                class="w-[90px] max-sm:w-[90px]  pt-0"
                src="../assets/img/URF.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ABOUT US ENDS HERE -->

  <OurServices />
  <OurServicesDesign />
  <div class="w-full h-[10vh] bg-[#159593]"></div>

  <div class="h-[100vh] max-md:h-auto background-gradient">
    <div class="w-full h-[100%] grid grid-cols-2 max-md:grid-cols-1 rdback">
      <div class="grid grid-cols-1 h-[60%] max-md:h-[15vh] mt-10 max-md:justify-items-center items-end">
        <p class="text-[68px] max-md:text-[48px] ml-14 max-md:ml-0 mt-7 font-['Inter'] font-bold">
          GRAPHICS ART
        </p>
        <p class="text-[18px] w-[100%] max-md:w-[80%] ml-14 max-md:mt-10 max-md:ml-0 max-md:text-center">
          Where innovation meets precision in the realm of architectural
          visualization. <br />
          We transform your blue prints into life like experiences.
        </p>
        <div class="w-[80%] flex justify-center items-center max-md:mt-10">
          <router-link
            :to="'/3Dmodeling'"
            class="green-background w-[140px] items-center flex justify-center max-sm:w-[180px] text-[20px] max-sm:text-[18px] mb-11 text-center max-md:mt-4 max-md:mb-4 h-[42px] max-sm:h-[42px] rounded-[30px] text-white hover:bg-[#0f7c7a]"
          >
            See More
          </router-link>
        </div>
      </div>
      <div class="w-full h-[100vh] max-md:h-[70vh] flex justify-center items-center max-md:items-end mt-6 max-md:mt-0 max-md:mb-10">
        <img class="w-[70%]" src="../assets/img/Chair12.png" alt="" />
      </div>
    </div>
  </div>
  <!-- /* Rectangle 74 */

box-sizing: border-box;

position: absolute;
width: 685px;
height: 676px;
left: 1073px;
top: 6485px;

border-width: 7px 3px 0px 3px;
border-style: solid;
border-color: #159593;
border-radius: 58px; -->
</template>

<script>
import OurServices from "../components/OurServices.vue";
import NavBar from "../components/Navbar.vue";
import OurServicesDesign from "../components/OurSerivesDesign.vue";
export default {
  name: "HomeView",
  components: {
    OurServices,
    NavBar,
    OurServicesDesign,
  },
};
</script>

<style scoped>
.rdback {
  background-image: url("../assets/img/3dback.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.green-font {
  color: #159593;
}

.blu-back {
  background-color: #262362;
}

.green-background {
  background-color: #159593;
}

.hover-green-background {
  background-color: #0f7c7a;
}

.home {
  background-image: url("../assets/img/Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: normal;
}
.background-gradient {
  background: linear-gradient(
    270deg,
    rgba(21, 149, 147, 0.31) 0%,
    #eef8f3 100%
  );
}

.fade-enter-from,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fade-enter-to,
.fade-leave

/* .fade-enter-active in <2.1.8 */ {
  opacity: 1;
}
</style>
