<template>
  <div class=" grid-container background-gradient  flex justify-center items-center">
    <!-- First Column (25% of the screen) -->
    <div class="lg:hidden">
      <div class="flex justify-center h-[50vh] items-start pt-16">
        <p class="text-[54px] font-['Bahnschrift'] font-extralight text-center">
          <span class="font-bold">DESIGN</span> & <br />
          <span class="font-bold">MARKETING</span> <br />
          SOLUTIONS <br />
          WE PROVIDE
        </p>
      </div>
      <div class="flex justify-center text-[20px] items-start h-[40vh] pt-16">
        <p class="text-center">
          At <span class="font-bold">SOZO</span>, we understand the <br />
          power of design and marketing <br />
          in shaping the identity and <br />
          success of businesses. Our <br />
          mission is to provide tailored <br />
          solutions that only elevate <br />
          your brand but also drive <br />
          tangible results.
        </p>
      </div>
    </div>

    <!-- Second Column (75% of the screen) -->
    <div class="w-full flex justify-center items-center max-md:hidden">
        <img class="h-[98vh]" draggable="false" src="../assets/img/final_transparent.png" alt="">

    </div>
    <div class="w-full grid grid-cols-1 lg:hidden">
      <div
        class="grid grid-cols-2 max-sm:grid-cols-1 justify-items-center items-center h-fit"
      >
        <div class="w-full h-fit grid grid-cols-1">
          <div class="w-full h-full flex justify-left ml-3 items-end">
            <div
              class="w-fit h-[50%] flex justify-center items-center p-5 rounded-[45px] bg-[#009391]"
            >
              <p class="text-[25px] max-md:text-[16px] text-white">Graphic & Motion Design</p>
            </div>
            <div
              class="w-fit p-3 h-[50%] max-md:h-[60%] rounded-[50px] ml-3 flex justify-center items-center bg-[#009391]"
            >
              <img class="w-[50%]" src="../assets/img/arrow (1).png" alt="" />
            </div>
          </div>
          <div class="w-full h-full flex justify-left items-center">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Logo Design</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Brand Identity</p>
            </div>
          </div>
          <div class="w-full h-full flex justify-left">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Marketing Designs</p>
            </div>
          </div>
        </div>


        <div class="w-full h-full grid grid-cols-1">
          <div class="w-full h-full flex justify-left ml-3 items-end">
            <div
              class="w-fit h-[50%] flex justify-center items-center p-5 rounded-[45px] bg-[#009391]"
            >
              <p class="text-[25px] max-md:text-[16px] text-white">Social Media Management</p>
            </div>
            <div
              class="w-fit p-3 h-[50%] max-md:h-[60%] rounded-[50px] ml-3 flex justify-center items-center bg-[#009391]"
            >
              <img class="w-[50%]" src="../assets/img/arrow (1).png" alt="" />
            </div>
          </div>
          <div class="w-full h-full flex justify-left items-center">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">SMM Campaigns</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">SM Content</p>
            </div>
          </div>
          <div class="w-full h-full flex justify-left">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Creating & Managing Sponsors</p>
            </div>
          </div>
        </div>
      </div>




      <div
        class="grid grid-cols-2 max-sm:grid-cols-1 justify-items-center items-center h-fit"
      >
        <div class="w-full h-fit grid grid-cols-1">
          <div class="w-full h-full flex justify-left items-end">
            <div
              class="w-fit h-[50%] flex justify-left ml-3 items-center rounded-[45px] p-5 bg-[#009391]"
            >
              <p class="text-[25px] max-md:text-[16px] text-white">UI/UX Design</p>
            </div>
            <div
              class="w-fit p-3 h-[50%] max-md:h-[60%] rounded-[50px] ml-3 flex justify-center items-center bg-[#009391]"
            >
              <img class="w-[50%]" src="../assets/img/arrow (1).png" alt="" />
            </div>
          </div>
          <div class="w-full h-full flex justify-left items-center">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Web Design</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Intuitive Navigation</p>
            </div>
          </div>
          <div class="w-full h-full flex justify-left items-start">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Interaction Design</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Mobile Design</p>
            </div>
          </div>
        </div>
        <div class="w-full h-fit grid grid-cols-1">
          <div class="w-full h-full flex justify-left items-end">
            <div
              class="w-fit h-[50%] flex justify-center ml-3 items-center p-5 rounded-[45px] bg-[#009391]"
            >
              <p class="text-[25px] max-md:text-[16px] text-white">Video Editing & Animations</p>
            </div>
            <div
              class="w-fit p-3 h-[50%] max-md:h-[60%] rounded-[50px] ml-3 flex justify-center items-center bg-[#009391]"
            >
              <img class="w-[50%]" src="../assets/img/arrow (1).png" alt="" />
            </div>
          </div>
          <div class="w-full h-full flex justify-left items-center">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Personalized Video Effects</p>
            </div>
          </div>
          <div class="w-full h-full flex justify-left">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Animations & Motion Graphics</p>
            </div>
          </div>
        </div>
      </div>




      <div
        class="grid grid-cols-1 max-sm:grid-cols-1 justify-items-center items-center h-fit"
      >
        <div class="w-full h-fit grid grid-cols-1">
          <div class="w-full h-full flex justify-left items-end">
            <div
              class="w-fit p-5 h-[50%] flex justify-center ml-3 items-center rounded-[45px] bg-[#009391]"
            >
              <p class="text-[25px] max-md:text-[16px] text-white">SEO & SEM Management</p>
            </div>
            <div
              class="w-[70px] h-[50%] max-md:h-[60%] rounded-[50px] ml-3 flex justify-center items-center bg-[#009391]"
            >
              <img class="w-[50%]" src="../assets/img/arrow (1).png" alt="" />
            </div>
          </div>
          <div class="w-full h-full flex flex-wrap max-sm:grid grid-cols-1 justify-left items-end">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Keyword Research</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">
                On-Page/ Off-Page Optimization
              </p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Content Creation</p>
            </div>
          </div>
          <div class="w-full h-full flex flex-wrap justify-left items-center">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[20%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Local SEO</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[20%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Technical SEO</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[20%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">PPC Advertising</p>
            </div>
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[20%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Display Advertising</p>
            </div>
          </div>
          <div class="w-full h-full flex flex-wrap justify-left items-start">
            <div
              class="w-fit p-5 border-2 border-black rounded-[45px] h-[40%] items-center justify-center flex m-3"
            >
              <p class="text-[25px] max-md:text-[16px] text-black">Analystics And Reporting</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
/* CSS to specify column widths */
.grid-container {

  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
}
@media (max-width: 1024px) {
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
    /* Single column layout for mobile */
    height: fit-content;
  }
}

/* Additional styling if needed */

.background-gradient {
  background: linear-gradient(
    270deg,
    rgba(21, 149, 147, 0.31) 0%,
    #eef8f3 100%
  );
}
.green-font {
  color: #159593;
}

.green-background {
  background-color: #159593;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-green-background {
  background-color: #0f7c7a;
}

.hero {
  background-image: url("../assets/img/33905957.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: normal;
}

.services {
  background-image: url("../assets/img/OurServices\ Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: normal;
}
</style>
