<template>
  <div class="bg-black/100 services pt-[70px] h-full pb-10">
    <div class="grid grid-cols-1">
      <div class="flex gap-3 mt-9 ml-[35px]">
        <div class="w-[37px] mt-7 max-sm:mt-7 h-[3px] green-background"></div>
        <p class="text-[40px] max-sm:text-[40px] w-[363px] text-white font-['Bahnschrift'] font-regular">
          Our Services
        </p>
      </div>
      
      <div class="text-center max-sm:mt-10 lg:mt-[2rem]">
        <p class="text-[25px] max-sm:text-[13px] text-white font-['Bahnschrift'] font-semibold md:mt-6">
          Empower your business with our web and software development services.
        </p>
        <p class="text-[25px] max-sm:text-[13px] text-white font-['Bahnschrift'] font-semibold">
          We specialize in providing high-quality solutions
          <br class="lg:hidden" />
          tailored to fit your unique needs.
        </p>
      </div>

      <div class="mt-10 h-[auto]">
        <div
          class="text-sm font-medium text-center flex justify-center max-sm:flex-wrap text-gray-500 dark:text-gray-400">
          <ul class="flex flex-wrap -mb-px">
            <li v-for="(tab, index) in tabContent" :key="index" class="max-sm:w-[100%] r-2">
              <div id="tabContent" class=""></div>
              <a href="#tabContent" @click="currentTab = index" class="inline-block p-4 rounded-t-lg cursor-pointer"
                :class="{
                  'text-gray-600 border-b-2 border-gray-300 dark:text-gray-300 hover:border-[#159593] hover:text-white':
                    currentTab !== index,
                  'text-white border-[#159593] border-b-2 dark:text-[#159593]':
                    currentTab === index,
                }">{{ tab }}</a>
                
            </li>
            
          </ul>
        </div>
        
        <div class="p-4 flex h-[60vh] max-sm:h-[70vh] justify-center ">
          <div class="text-white text-center max-sm:text-left max-sm:text-[15px] text-[20px] font-Bahnschrift font-light"
            v-html="tabContent1[currentTab]"></div>
        </div>
        <div class="flex relative justify-center md:mb-6">
          <router-link :to="'/' + tabContent[currentTab].toLowerCase().replace(/ /g, '-')"
            class="green-background w-[240px] max-sm:w-[180px] text-[20px] max-sm:text-[18px] mb-11 text-center max-md:mt-4 max-md:mb-4 h-[62px] max-sm:h-[42px] rounded-[30px] text-white hover:bg-[#0f7c7a]">
            See More
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      currentTab: 0,
      tabContent: [
        "Custom Software Development",
        "Web Application Development",
        "E-commerce Development",
        "Database Development",
        "Mobile Application Development",
      ],
      tabContent1: [
        `We Offer :<br>

- Software Development: Tailored applications designed for your specific needs.<br>
- Scalable Solutions: Software that grows with your business.<br>
- User-Centric Design: Intuitive interfaces for enhanced usability.<br>
- Ongoing Support: Continuous assistance to maintain software performance.<br>
- Data Security: Robust measures to protect your valuable information.<br>
- Integration Services: Seamlessly connect with other systems.<br>
- Performance Optimization: Ensuring your software runs at its best.<br>
Count on us to deliver software solutions that align with your objectives and drive your business forward."`,

        `Introducing our Website Development services - we specialize in creating stunning and functional websites tailored to your needs.<br>
      <br>  
      Our services include:
      <br>
      <br>
        - Custom Website Development: Hand-coded or WordPress websites for a unique online presence <br>
        - Responsive Web Design: Websites that adapt seamlessly to different devices<br>
        - Content Management Systems (CMS): User-friendly platforms for easy content management<br>
        - Website Maintenance and Support: Ongoing services to keep your website secure and up-to-date<br>
        - Landing Page Design: Compelling pages to drive conversions<br>
        - SEO Optimization: Enhancing search engine visibility<br>
        - Website Analytics and Reporting: Comprehensive tracking and performance measurement<br>
        Trust us to deliver visually appealing and user-friendly websites that represent your brand and help you succeed online.`,

        "We offer:<br>- Tailored Online Stores: Customized platforms designed to meet your specific E-commerce needs.<br>- Scalable E-commerce Solutions: Websites that seamlessly expand as your business grows.<br>- User-Focused E-commerce Design: Intuitive interfaces for a smooth and engaging shopping experience.<br>- Ongoing E-commerce Support: Continuous assistance to maintain optimal store performance.<br>- E-commerce Security Measures: Robust strategies to ensure the safety of your online transactions.<br>- Integration Services: Seamless connections with payment gateways and other E-commerce systems.<br>- E-commerce Performance Optimization: Ensuring your online store operates at peak efficiency.<br>- Analytics Integration: Implementing analytics tools to track and analyze user behavior, enabling data-driven decision-making.<br>Count on us to deliver E-commerce solutions that enhance your online business, drive sales, and provide valuable insights through analytics.",
        "In the realm of Database Development, we offer:<br>- Tailored Database Solutions: Customized databases designed to meet your specific data management requirements.<br>- Scalable Database Architecture: Systems that can seamlessly grow to accommodate expanding data needs.<br>- User-Friendly Database Design: Intuitive structures for efficient data storage and retrieval.<br>- Ongoing Database Support: Continuous assistance to ensure optimal database performance and reliability.<br>- Robust Data Security Measures: Implementing strong measures to safeguard your valuable information.<br>- Integration Services: Seamless connections with other systems for efficient data flow.<br>- Database Performance Optimization: Ensuring your database operates at peak efficiency for swift and reliable data processing.<br>Count on us to deliver Database Development solutions that align with your data management objectives and enhance overall efficiency.",
        "In Mobile Application Development, we provide:<br>- Tailored App Solutions: Customized mobile applications designed to meet your specific business needs.<br>- Scalable App Architecture: Platforms that can seamlessly grow as your user base expands.<br>- User-Centric App Design: Intuitive interfaces for an enhanced and enjoyable user experience.<br>- Ongoing App Support: Continuous assistance to maintain optimal app performance and functionality.<br>- App Security Measures: Robust strategies to ensure the safety of user data and sensitive information.<br>- Integration Services: Seamless connections with other systems for enhanced functionality.<br>- App Performance Optimization: Ensuring your mobile app operates at peak efficiency for a smooth user experience.",
      ],
    };
  },
};
</script>
<style scoped>
.green-font {
  color: #159593;
}

.green-background {
  background-color: #159593;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hover-green-background {
  background-color: #0f7c7a;
}

.hero {
  background-image: url("../assets/img/33905957.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: normal;
}

.services {
  background-image: url("../assets/img/OurServices\ Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: normal;
}
</style>